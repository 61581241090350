body::-webkit-scrollbar {
    width: 1.25em; /* Total width including `border-width` of scrollbar thumb */
    height: 0;
    box-shadow: inset 0 0 100px #2E3547;
    border-radius: 10px;
    margin-right: 20px;
    
    &-thumb{
        height: 1em;
        border: 0.3em solid rgba(0, 0, 0, 0); /* Transparent border together with `background-clip: padding-box` does the trick */
        background-clip: padding-box;
        -webkit-border-radius: 0.5;
        background-color: rgba(202, 203, 211, 0.2);
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
    }

    &-track{
        -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 1);
        border-radius: 10px;
        padding: 10px;
    }
}
